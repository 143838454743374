<template>
  <div class="rm-content">
    <div class="primary-section-content">
      <div v-if="isPropertyLevel">
        <HbPropertySelector
          v-if="isPropertyLevel && hasProperties"
          v-model="propertyId"
          :items="properties"
          item-value="id"
          item-text="name"
          :loading="false"
          :disabled="false"
        />
      </div>

      <div class="my-3 mr-6" v-else>
        <hb-notification
          v-model="warning"
          v-if="warning"
          type="caution"
          title-off
          @close="warning = false"
        >
          The Corporate Settings will apply as a default to all properties but
          can be overridden in the Property Settings.
        </hb-notification>
      </div>
      <div v-if="isPropertyLevel && !propertyId" class="mr-6">
        <hb-empty-state
          message="Please select a Property to continue."
        ></hb-empty-state>
      </div>
      <CharmSettings
                      
                      :documents="documents"
                      :is-property-level="isPropertyLevel"
                      :level="currentLevel"
                      :property="propertyId"
                    
                      @setSnackBar="setSnackNotification($event)"
                    />
      
    </div>

    <hb-global-notification
      v-model="snackbar"
      :type="type"
      :description="description"
      :list="errorsList"
    ></hb-global-notification>

  </div>
</template>

<script type="text/babel">
import { mapGetters } from "vuex";
import CharmSettings from "./charmSettings.vue";

export default {
  name: "charm-settings",
  props: {
    level: {
      type: String,
      default: "Property",
    },
  },
  data() {
    return {
      warning: true,
      snackbar: false,
      type: "",
      description: "",
      errorsList: [],
      propertyId: null,
      hasProperties: false,
      propertyLoading: false,
      documents: [],
      openPanel: [0, 1],
    };
  },
  components: {
    CharmSettings,
  },
  computed: {
    ...mapGetters({
      properties: "propertiesStore/filtered",
      hasRolePermission: "authenticationStore/rolePermission",
    }),
    /**
     * @returns {String} - current level in lowercase
     */
    currentLevel() {
      return this.level.toLowerCase();
    },

    /**
     * @returns {Boolean} - true if current level is property, else false
     */
    isPropertyLevel() {
      return this.level === "Property";
    },

    /**
     * To return an array of accordion numbers which needs to be open by default
     *  - For Corporate level the accordion should be opened
     *  - For Property level the accordion will be opened only if property is selected
     *
     * @returns {Array} - An array of accordion numbers
     */
    panel() {
      return this.isPropertyLevel && !this.propertyId
        ? []
        : !this.isPropertyLevel
        ? [0, 1]
        : this.openPanel;
    },
  },

  watch: {
    isPropertyLevel: {
      async handler(val) {
        if (val) {
          if (this.properties.length > 1) {
            this.propertyId = "";
            this.hasProperties = true;
          } else if (this.properties.length === 1) {
            this.propertyId = this.properties[0].id;
            this.hasProperties = false;
          } else this.propertyId = "";
        
        }
      },
      immediate: true,
    },
    propertyId: {
      async handler(val) {
        if (this.isPropertyLevel && val) {
          this.propertyLoading = true;
        }
      },
      immediate: true,
    },
  },

  methods: {

    setSnackNotification(data) {
      this.setSnackbar(data.type, data.message, data.errors);
    },

    /**
     * Triggers snackbar components with the given data
     *
     * @param {String} snackbarType - can be 'success' or 'error'
     * @param {String} snackbarText - text to be displayed in snackbar
     * @param {Array} errorList - list of errors if any.
     */
    setSnackbar(snackbarType, snackbarText, errorList = []) {
      this.snackbar = true;
      this.type = snackbarType;
      this.description = snackbarText;
      this.errorsList = errorList;
      setTimeout(() => {
        this.snackbar = false;
      }, 6000);
    },

  },
};
</script>

<style lang="scss">
.notificationMsg {
  textarea {
    line-height: 35px;
  }
}

.merge-field-text-wrap {
  a {
    i {
      margin-bottom: 2px;
    }
  }
}
</style>
