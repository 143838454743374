<template>
  <div>
      
      <hb-modal
          size="large"
          title="Update Voicemail"
          v-model="showForm"  
          @close="$emit('close')" 
          show-help-link
      >
      <template v-slot:subheader>
        The Corporate Settings will apply as a default to all properties but can be overridden in the Property Settings.
      </template>
      <template v-slot:content >
          <div class="pa-4" style="background: #F9FAFB; height: 502.5px; overflow: auto ">
  
              <v-expansion-panels v-model="form_panels" multiple>
                  <hb-expansion-panel class="mb-2">
                      <template v-slot:title>
                         Voicemail Settings
                      </template>
                      <template v-slot:content>
                          
                          <hb-form label="Voicemail" required multiple>
                              <v-row >
                                  <v-col class="py-0"> 
                                    {{voicemailData.text}}
                                     
                                  </v-col>
                              </v-row>
                          </hb-form>                     
                          <hb-form label="voiceType" required multiple>
                              <v-row >
                                  <v-col class="py-0"> 
                                    {{ getVoiceSelected()}}
                                  </v-col>
                              </v-row>
                          </hb-form>  
                      
  
                      </template>
                  </hb-expansion-panel>
              </v-expansion-panels>
          </div>
      </template>
  
      <template v-slot:right-actions>
          <hb-btn :disabled="loading" :loading="loading" @click="save">Update Information</hb-btn>
      </template> 
    
      </hb-modal>
  </div>
  </template>            
  
  <script type="text/babel">
 
  import api from "../../../assets/api.js";
  export default {
      name: "UpdateForm",
      props: {
        voicemailData:{
           
          },
          

      },
      data() {
          return {

              showForm: true,
              form_panels: [0],
             
            
              loading: false,
           
          }
      },
      computed: {
        
      },
      mounted() {
          
      },
      methods: {
        getVoiceSelected(){
            return this.voicemailData.voiceType === "1" ? "Male" : this.voicemailData.voiceType === "2" ? "Female"  : ""  
        },
          async save() {
          
            this.loading = true
            
          await  api.post(this, api.CHARM_VOICEMAIL, {"voicemail_text":this.voicemailData.text,"voice_type":this.voicemailData.voiceType}).then((ele)=>{
                this.loading = false
                this.$emit('close')
                this.$emit('success',"voice mail updated successfully")
            })
            .catch((err)=>{
                console.log(err)
            this.loading = false
            this.$emit('close')
            this.$emit('error',"Something went wrong")
           
            })
           
            
           
          }
      },
  }
  </script>